export default defineI18nConfig((nuxt) => ({
  legacy: false,
  locale: "uk",
  messages: {
    uk: {
      fieldName: "Ім’я",
      fieldPhone: "Телефон",
      fillInData: "Введіть ім’я і телефон",
      closeBtn: "Закрити",
    },
  },
}));
